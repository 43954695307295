// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
    --height: 28px;
    --border-radius: 100px;
}    
  
ion-progress-bar {
    height: var(--height);
    --progress-background: var(--ion-color-primary-medium-tint);
    --background: var(--ion-color-light);
    border-radius: var(--border-radius);
}

ion-text {
    position: absolute;
}

div {
    /* Use flex div to display text over the percentagebar */
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--ion-color-medium-shade);
    border-radius: var(--border-radius);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/genericComponents/flat/flat-widget-percentagebar/flat-widget-percentagebar.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,sBAAsB;AAC1B;;AAEA;IACI,qBAAqB;IACrB,2DAA2D;IAC3D,oCAAoC;IACpC,mCAAmC;AACvC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,wDAAwD;IACxD,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,+CAA+C;IAC/C,mCAAmC;AACvC","sourcesContent":[":host {\n    --height: 28px;\n    --border-radius: 100px;\n}    \n  \nion-progress-bar {\n    height: var(--height);\n    --progress-background: var(--ion-color-primary-medium-tint);\n    --background: var(--ion-color-light);\n    border-radius: var(--border-radius);\n}\n\nion-text {\n    position: absolute;\n}\n\ndiv {\n    /* Use flex div to display text over the percentagebar */\n    color: black;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid var(--ion-color-medium-shade);\n    border-radius: var(--border-radius);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
