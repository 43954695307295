// @ts-strict-ignore
import { Component, inject, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Icon } from 'src/app/shared/type/widget';
import { ModalController } from '@ionic/angular';
import { InfoModalComponent } from '../modal/info-modal/info-modal';
//import { ModalComponent } from 'src/app/edge/live/common/grid/modal/modal';

@Component({
  selector: 'oe-flat-widget',
  templateUrl: './flat.html',
  styleUrls: ['./flat.css'],
  providers: [TranslateService, ModalController]
})
export class FlatWidgetComponent {
  private translateService = inject(TranslateService);
  private modalController = inject(ModalController);

  /** Image in Header */
  @Input() public img: string;

  /** Icon in Header */
  @Input() public icon: Icon = null;

  /** BackgroundColor of the Header (light or dark) */
  @Input() public color: string;

  /** Title in Header */
  @Input() public title: string;

  /** This property defines what info text is pulled from the translation service when clicking on the info button of the widget */
  @Input() public info: string;

  async showInfoModal() {
    const modal = await this.modalController.create({
      component: InfoModalComponent,
      componentProps: {
        title: this.title,
        info: this.info,
      }
    });
    return await modal.present();
  }
}
