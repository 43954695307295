import { Component, Input } from '@angular/core';
import { AbstractFormlyComponent, OeFormlyView, OeFormlyField } from 'src/app/shared/genericComponents/shared/oe-formly-component';
import { EdgeConfig } from 'src/app/shared/shared';
import { Role } from 'src/app/shared/type/role';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from '../modal';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.html',
  styleUrls: ['./info-modal.css']
})

export class InfoModalComponent {
  @Input()
  title: string = "";

  @Input()
  info: string = "";
}
