// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

ion-button {
    margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/edge/history/common/energy/flat/flat.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;AACzC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".button-row {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n}\n\nion-button {\n    margin-left: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
