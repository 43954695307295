// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

    :host[data-isSmartPhone=true] {
      position: relative;
    }

    :host[data-isSmartPhone=false] {
      position: sticky;
      bottom: 0;
      width: 100%;

      font-size: 14px !important;
      :is(ion-row) {
        text-align: center;
      }

      :is(ion-item) {
        --min-height: initial !important;
        font-size: inherit;
      }
    }
  `, "",{"version":3,"sources":["webpack://./src/app/shared/footer/footer.ts"],"names":[],"mappings":";;IAEI;MACE,kBAAkB;IACpB;;IAEA;MACE,gBAAgB;MAChB,SAAS;MACT,WAAW;;MAEX,0BAA0B;MAC1B;QACE,kBAAkB;MACpB;;MAEA;QACE,gCAAgC;QAChC,kBAAkB;MACpB;IACF","sourcesContent":["\n\n    :host[data-isSmartPhone=true] {\n      position: relative;\n    }\n\n    :host[data-isSmartPhone=false] {\n      position: sticky;\n      bottom: 0;\n      width: 100%;\n\n      font-size: 14px !important;\n      :is(ion-row) {\n        text-align: center;\n      }\n\n      :is(ion-item) {\n        --min-height: initial !important;\n        font-size: inherit;\n      }\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
