// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
    --background: white;
}

ion-searchbar {
    --border-radius: var(--default-border-radius);
    --background: var(--ion-color-light-shade);
    --box-shadow: None;
}`, "",{"version":3,"sources":["webpack://./src/app/index/overview/overview.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,6CAA6C;IAC7C,0CAA0C;IAC1C,kBAAkB;AACtB","sourcesContent":["ion-content {\n    --background: white;\n}\n\nion-searchbar {\n    --border-radius: var(--default-border-radius);\n    --background: var(--ion-color-light-shade);\n    --box-shadow: None;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
